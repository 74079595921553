const sponsor = {
    bmw: require('./assets/images/bmw.png'),
    chevrolet: require('./assets/images/chevrolet.png'),
    ferrari: require('./assets/images/ferrari.png'),
    hondaM: require('./assets/images/hondaM.png'),
    honda: require('./assets/images/honda.png'),
    mercedes: require('./assets/images/mercedes.png'),
    mitsu: require('./assets/images/mitsu.png'),
    nissan: require('./assets/images/nissan.png'),
    porsche: require('./assets/images/porsche.png'),
    shell: require('./assets/images/shell.png'),
    suzuki: require('./assets/images/suzuki.png'),
    toyota: require('./assets/images/toyota.png'),
    volkswagen: require('./assets/images/volkswagen.png'),
    volvo: require('./assets/images/volvo.png'),
    yamaha: require('./assets/images/yamaha.png'),
  };
  
  export default sponsor;
  